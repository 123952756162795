<template>
  <div>
    <h1 class="mt-4 mb-4">Solicitudes</h1>

    <p>
      Aqui puedes solicitar contenido para que se suba al servidor y ver el que ya has solicitado.
    </p>

    <div class="row">

      <div class="col-sm text-center m-1 p-2 shadow border bg-light">
        <button type="button" @click="solicitarPelicula = !solicitarPelicula" class="btn m-1 btn-outline-success"><i class="fas fa-film"></i> Solicitar una película</button>

        <div v-if="solicitarPelicula">
          <SolicitarPelicula @update-solicitudes="getSolicitudes"></SolicitarPelicula>
        </div>
      </div>

      <div class="col-sm text-center m-1 p-2 shadow border bg-light">
        <button type="button" @click="solicitarSerie = !solicitarSerie" class="btn m-1 btn-outline-success"><i class="fas fa-tv"></i> Solicitar una serie/documental/dibujos</button>

        <div v-if="solicitarSerie">
          <SolicitarSerie @update-solicitudes="getSolicitudes"></SolicitarSerie>
        </div>
      </div>

    </div>

    <div class="mt-3">
      <p v-show="errorSolicitudes">Error obteniendo las solicitudes</p>

      <h3 v-show="loadingSolicitudes" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

      <div v-if="!errorSolicitudes && !loadingSolicitudes" class="text-center">
        <p><strong>{{ solicitudes.length }}</strong> solicitud/es</p>

        <Solicitud v-for="solicitud in solicitudes" :key="solicitud._id" @update-solicitudes="getSolicitudes" :solicitud="solicitud"></Solicitud>

      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'
  import Solicitud from '../components/solicitudes/Solicitud.vue'
  import SolicitarPelicula from '../components/solicitudes/SolicitarPelicula.vue'
  import SolicitarSerie from '../components/solicitudes/SolicitarSerie.vue'

  export default {
    data() {
      return {
        solicitudes: [],
        loadingSolicitudes: true,
        errorSolicitudes: false,
        solicitarPelicula: false,
        solicitarSerie: false,
      }
    },
    methods: {
      async getSolicitudes() {
        try {
          this.loadingSolicitudes = true
          const res = await axios.get(config.apiUrl + '/solicitudes')
          this.loadingSolicitudes = false
          this.solicitudes = res.data
        } catch (e) {
          this.loadingSolicitudes = false
          this.errorSolicitudes = true
        }
      }
    },
    components: {
      Solicitud,
      SolicitarPelicula,
      SolicitarSerie,
    },
    mounted() {
      this.$store.commit('setPanelLayout')
      this.getSolicitudes()
    }
  }
</script>

<template>
  <div class="text-left">
    <hr>

    <h5>Elige la película:</h5>

    <Multiselect
      v-model="selectedOption"
      :close-on-select="true"
      :options="options"
      @search-change="searchTmdb"
      placeholder="Busca una película"
      :internal-search="false"
      :clear-on-select="true"
      :custom-label="nameWithYear"
    >
      <template
        slot="option"
        slot-scope="props"
      >
        <img
          class="option__image"
          :src="'https://image.tmdb.org/t/p/h100' + props.option.imagen"
          :alt="props.option.titulo"
        >
        <span class="option__desc">
          <span class="option__title">
            {{ props.option.titulo }} ({{ props.option.fecha }})
          </span>
        </span>
      </template>
    </Multiselect>

    <div class="text-center mt-2">
      <LoadingButton @clicked="requestMovie" :iconProp="'fas fa-paper-plane'" :loadingProp="requesting" :classProp="'btn btn-outline-info'" :textProp="'Solicitar'"></LoadingButton>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import _ from 'lodash'
  import config from '../../config'
  import LoadingButton from '../LoadingButton.vue'

  export default {
    data() {
      return {
        selectedOption: null,
        options: [],
        requesting: false
      }
    },
    components: {
      Multiselect,
      LoadingButton
    },
    methods: {
      searchTmdb: _.debounce(async function(query) {
        try {

          if (!query) {
            return
          }

          const res = await axios.get('https://api.themoviedb.org/3/search/movie?language=es&api_key=34213f6650be159c39dfe30df7ecb001&query=' + query)
          const results = res.data.results

          const options = []

          for (let i = 0; i < results.length; i++) {
            options.push({
              tipo: 'pelicula',
              tmdbId: results[i].id,
              titulo: results[i].title,
              fecha: results[i].release_date,
              imagen: results[i].poster_path,
              nota: results[i].vote_average
            })
          }

          this.options = options
        } catch (e) {
          Swal.fire({
            title: 'Error',
            text: 'Ha habido un error buscando la película, inténtalo más tarde',
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }, 200),
      nameWithYear(option) {
        return `${option.titulo} (${option.fecha})`
      },
      async requestMovie() {
        try {
          if (!this.selectedOption) {
            return Swal.fire({
              title: 'Error',
              text: 'Tienes que elegir una película',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            })
          }

          this.requesting = true

          const res = await axios.post(config.apiUrl + '/solicitudes/nueva', this.selectedOption)

          this.requesting = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.$emit('update-solicitudes')

        } catch (e) {
          this.requesting = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          if (e.response) {
            if (e.response.status === 401) {
              this.$router.push('login')
              return
            }
          }
        }
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
